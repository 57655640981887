.title{
    text-transform: uppercase;
    text-align: center;
    margin: 20px;
    font-weight: bold;
    color: #173928;
    margin-bottom: 0px;
    font-size: 26px;
}

.line{
    width: 25%;
    border-top: 5px solid #1FBC6D;
}