.footer{
    background: #173928;
    color: white;
}

.footer address{
    font-size: 12px;
}

.copy{
    background: #1FBC6D;
    padding: 10px;
    text-align: center;
    color: #173928;
}

.copy p{
    margin: 0;
    padding: 0;
}