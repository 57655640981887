.topbar{
    height: 30px;
    background: #1FBC6D;
}

.logo{
    width: 200px;
    display: block;
    margin: 20px auto;
}

.backNavBar{
    background: #173928;
    font-weight: bold;
    padding: 17px !important;
}

.navlink{
    color: white;
    margin: 15px;
    font-size: 12px;
}

.navlink:hover{
    color: #1FBC6D;
}

.toggler{
    border-color: white !important;
}